import loadable from '@loadable/component';
import { FC, Suspense, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Page } from './types';
import { isValidToken, setSession } from 'utils/jwt';
import { useSelector, useDispatch } from 'react-redux';
import api from 'utils/api';
import { AnimatePresence } from 'framer-motion';
import { Toaster } from 'react-hot-toast';

import { ROUTES } from './routes';
import PrivateRoute from './PrivateRoute';
import PublicOnlyRoute from './PublicOnlyRoute';

import ScrollToTop from './ScrollToTop';

import { FallbackComponent } from 'components/LoadingScreen/LoadingScreen';
const loadableOptions = { fallback: <FallbackComponent /> };

const PageHome = loadable(
  () => import('containers/PageHome/PageHome'),
  loadableOptions
);
const SignUpPage = loadable(
  () => import('pages/Auth/SignUpPage/SignUpV2Page'),
  loadableOptions
);
const SignUpGreetherPage = loadable(
  () => import('pages/Auth/SignUpPage/SignUpGreetherPage'),
  loadableOptions
);
const LoginPage = loadable(
  () => import('pages/Auth/LoginPage'),
  loadableOptions
);
const BookingStepOnePage = loadable(
  () => import('pages/Booking/StepOnePage/'),
  loadableOptions
);
const BookingStepTwoPage = loadable(
  () => import('pages/Booking/StepTwoPage/'),
  loadableOptions
);
const BookingStepThreePage = loadable(
  () => import('pages/Booking/StepThreePage/'),
  loadableOptions
);
const AdditionalTravelerRequirements = loadable(
  () => import('pages/Booking/AdditionalTravelerRequirements'),
  loadableOptions
);
const BookingConfirmed = loadable(
  () => import('pages/Booking/BookingConfirmedPage/BookingConfirmedPage'),
  loadableOptions
);
const BookingCheckoutPage = loadable(
  () => import('pages/Booking/CheckoutPage'),
  loadableOptions
);
const Page404 = loadable(
  () => import('containers/Page404/Page404'),
  loadableOptions
);
const FAQTravelerPage = loadable(
  () => import('pages/FAQPage/FAQTravelerPage'),
  loadableOptions
);
const FAQGreetherPage = loadable(
  () => import('pages/FAQPage/FAQGreetherPage'),
  loadableOptions
);
const PrivacyPolicy = loadable(
  () => import('pages/PrivacyPolicy/PrivacyPolicy'),
  loadableOptions
);
const ConfirmEmailPage = loadable(
  () => import('pages/Auth/ConfirmationPage/ConfirmationPage')
);
const AwaitingConfirmationPage = loadable(
  () => import('pages/Auth/ConfirmationPage/AwaitingConfirmationPage'),
  loadableOptions
);
const CancellationPolicyPage = loadable(
  () => import('pages/FAQPage/CancellationPolicyPage'),
  loadableOptions
);
const NewPasswordPage = loadable(
  () => import('pages/Auth/NewPasswordPage/NewPasswordPage'),
  loadableOptions
);
const ListingExperiencesDetailPage = loadable(
  () => import('containers/ListingDetailPage/ListingExperiencesDetailPage'),
  loadableOptions
);
const ListingStayPage = loadable(
  () => import('containers/ListingStayPage/ListingStayPage'),
  loadableOptions
);
const UserPage = loadable(
  () => import('containers/UserPage/UserPage'),
  loadableOptions
);
const MyProfilePage = loadable(
  () => import('pages/MyProfilePage/MyProfilePage'),
  loadableOptions
);
const PageForgotPassword = loadable(
  () => import('containers/PageForgotPassword/PageForgotPassword'),
  loadableOptions
);

const DefineYourJourneyPage = loadable(
  () => import('pages/Auth/DefineYourJourneyPage/DefineYourJourneyV2Page'),
  loadableOptions
);

const DefineYourJourneyNextPage = loadable(
  () => import('pages/Auth/DefineYourJourneyPage/StepThreePage'),
  loadableOptions
);

const DefineYourJourneyFinishPage = loadable(
  () => import('pages/Auth/DefineYourJourneyPage/ConfirmateProfile'),
  loadableOptions
);

type ExternalRedirectProps = {
  to: string;
};

export const pages: Page[] = [
  { path: '/', exact: true, component: PageHome as FC<any> },
  { path: '/#', exact: true, component: PageHome as FC<any> },
];

const Routes = () => {
  const dispatch = useDispatch();
  const tokenLocal = localStorage.getItem('accessToken');
  const hasToken = isValidToken(tokenLocal);
  const state = useSelector((state: any) => state.user);

  const getUserInformation = async () => {
    if (!tokenLocal) return;
    return await api('/auth/my-account', 'POST', {}, false, {
      status: true,
      access_token: tokenLocal,
    });
  };

  if (Object.keys(state).length === 0 && hasToken) {
    getUserInformation().then((res) => {
      dispatch({ type: 'loadAccount', payload: res });
    });
  } else if (!hasToken) {
    setSession('');
  }

  const userRole = state?.info?.account?.role || undefined;

  const ExternalRedirect: FC<ExternalRedirectProps> = ({ to }) => {
    useEffect(() => {
      window.location.href = to;
    }, [to]);

    return null;
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<FallbackComponent />}>
        <Toaster position="top-right" reverseOrder={false} />
        <AnimatePresence exitBeforeEnter>
          <Switch>
            {pages.map(({ component, path, exact }) => {
              return (
                <Route
                  key={path}
                  component={component}
                  exact={!!exact}
                  path={path}
                />
              );
            })}
            <PublicOnlyRoute
              exact
              key="/login"
              path="/login"
              component={LoginPage as FC<any>}
            />
            <PublicOnlyRoute
              exact
              key="/sign-up"
              path={ROUTES.SIGNUP}
              component={SignUpPage as FC<any>}
            />
            <PublicOnlyRoute
              exact
              path={ROUTES.SIGNUP_GREETER}
              component={SignUpGreetherPage as FC<any>}
            />
            <PublicOnlyRoute
              exact
              path={ROUTES.AWAITINGCONFIRMATION}
              component={AwaitingConfirmationPage as FC<any>}
            />
            <PublicOnlyRoute
              exact
              path={ROUTES.CONFIRMEMAIL}
              component={ConfirmEmailPage as FC<any>}
            />
            <PublicOnlyRoute
              exact
              path={ROUTES.NEW_PASSWORD}
              component={NewPasswordPage as FC<any>}
            />
            <Route exact path={ROUTES.FAQGREETER} component={FAQGreetherPage} />
            <Route
              exact
              path={ROUTES.FAQTRAVELER}
              component={FAQTravelerPage}
            />
            <Route
              exact
              path={ROUTES.PRIVACY_POLICY}
              component={PrivacyPolicy}
            />
            <Route
              exact
              path={ROUTES.CANCELLATIONPOLICY}
              component={CancellationPolicyPage}
            />

            <Route
              exact
              path={ROUTES.BOOKINGSTEPONE}
              component={BookingStepOnePage}
            />
            <PrivateRoute
              exact
              path={ROUTES.BOOKINGSTEPTWO}
              component={BookingStepTwoPage as FC<any>}
            />
            <PrivateRoute
              exact
              path={ROUTES.ADDITIONAL_REQUIREMENTS}
              component={AdditionalTravelerRequirements as FC<any>}
            />
            <PrivateRoute
              exact
              path={ROUTES.BOOKINGSTEPTHREE}
              component={BookingStepThreePage as FC<any>}
            />
            <PrivateRoute
              exact
              path={ROUTES.CHECKOUT}
              component={BookingCheckoutPage as FC<any>}
            />
            <PrivateRoute
              exact
              path={ROUTES.BOOKINGCONFIRMED}
              component={BookingConfirmed as FC<any>}
            />

            <PrivateRoute
              exact
              path={ROUTES.QUESTIONNAIRE}
              component={() => <DefineYourJourneyPage userRole={userRole} />}
            />

            <PrivateRoute
              exact
              path={ROUTES.QUESTIONNAIRENEXT}
              component={() => (
                <DefineYourJourneyNextPage userRole={userRole} />
              )}
            />

            <PrivateRoute
              exact
              path={ROUTES.QUESTIONNAIREFINISH}
              component={() => (
                <DefineYourJourneyFinishPage userRole={userRole} />
              )}
            />

            <Route exact key="/forgot-password" path="/forgot-password">
              {hasToken ? <Redirect to="/" /> : <PageForgotPassword />}
            </Route>
            <Route exact key="/my-profile" path="/my-profile">
              {hasToken ? <MyProfilePage /> : <Redirect to="/" />}
            </Route>
            <Route exact key="/p/:userId" path="/p/:userId">
              {hasToken ? <UserPage userRole={userRole} /> : <SignUpPage />}
            </Route>
            <Route exact key="/b/:userName" path="/b/:userName">
              {hasToken ? <ListingExperiencesDetailPage /> : <SignUpPage />}
            </Route>
            <Route exact key="/search-greeters" path="/search-greeters">
              {hasToken ? <ListingStayPage /> : <SignUpPage />}
            </Route>

            <Route exact path="/contact">
              <ExternalRedirect to="https://www.hello.greether.com/contactgreether" />
            </Route>
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>
        </AnimatePresence>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
