import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isValidToken } from 'utils/jwt';

interface PublicOnlyRouteProps extends Omit<RouteProps, 'component'> {
  component: React.FC<any>;
}

const PublicOnlyRoute: React.FC<PublicOnlyRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const tokenLocal = localStorage.getItem('accessToken');
  const hasToken = isValidToken(tokenLocal);
  const redirect = window.localStorage.getItem('redirect');

  return (
    <Route
      {...rest}
      render={(props) => {
        return hasToken ? (
          redirect ? (
            <Redirect to={redirect} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default PublicOnlyRoute;
