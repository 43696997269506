export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  //SIGNUP: '/sign-up',
  SIGNUP: '/sign-up',
  SIGNUP_GREETER: '/sign-up-greeter',
  CONFIRMEMAIL: '/confirm-email',
  NEW_PASSWORD: '/new-password',
  AWAITINGCONFIRMATION: '/awaiting-confirmation',
  //QUESTIONNAIRE: '/define-your-journey',
  QUESTIONNAIRE: '/define-your-journey',
  QUESTIONNAIRENEXT: '/define-your-journey/next',
  QUESTIONNAIREFINISH: '/define-your-journey/finish',
  BOOKINGSTEPONE: '/booking-step-one',
  BOOKINGSTEPTWO: '/booking-step-two',
  ADDITIONAL_REQUIREMENTS: '/additional-requirements',
  BOOKINGSTEPTHREE: '/booking-step-three',
  CHECKOUT: '/booking-checkout',
  BOOKINGCONFIRMED: '/booking-confirmed',
  MYPROFILE: '/my-profile',
  PRIVACY_POLICY: '/privacy-policy',
  FAQGREETER: '/faq-greeter',
  FAQTRAVELER: '/faq-traveler',
  CANCELLATIONPOLICY: '/cancellation-policy',
  NON_FOUND: '/not-found',
} as const;
